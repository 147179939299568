export const CONFIGURATION = {
  binance: {
    key: 'fg6o9pzZz4glf9Ciw28WY26fOZCjSC6aPna9tPUAhV9OzK1Y6QKJ7UyFySG4SvkI',
    secret: 'SFXaWfRPpkLWJwDJizTes6lg0Ou8HWu9Pf3QADBq9Kej11Cca4E5VImcxexIAt4l',
    exchangeType: 'futures' // spot is not supported yet
  },
  profile: {
    name: 'dashboard'
  }
};

